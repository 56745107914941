import React from "react";
import Layout from '../components/layout';
import HomeStyle from './home.module.scss'
import { Link } from 'gatsby';
import Biopic from '../components/Biopic.js'
import { Card, Button, CardDeck, Jumbotron} from 'react-bootstrap';

const IndexPage  = () => {
    

    return (
        <Layout>
            <div className={HomeStyle.row}>
                <Jumbotron className={HomeStyle.pcolumn}>
                    <Biopic/>
                    <br></br>
                    <br></br>
                    <h2 className={HomeStyle.siteTitle}>USE ADVANCED ANALYTICS</h2>
                    <h1 className = {HomeStyle.siteTitle}>GAIN AN EDGE</h1>
                    <br></br>
                    <p className={HomeStyle.pTitle}>
                        Finding good wagers is tough and time consuming. Using analytics to find better wagers means you can 
                        spend more time sweating out the photo finish or buzzer beater!  Enjoy!
                    </p>
                    <br></br>
                    <br></br>
                </Jumbotron>                
                
            </div>
            <hr></hr>
            <h3>Projects</h3>
            <CardDeck style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginRight: '0px', marginLeft: '0px'}}>
                <Card  className = {HomeStyle.card}>
                <Card.Body className = {HomeStyle.cardBody}>
                    <Card.Title className={HomeStyle.info}><b>Horse Racing</b></Card.Title>
                    <Card.Text className = {HomeStyle.par}>
                        Using XGBoost and Monte Carlo simulation I built a model that uses a wide variety of handicapping factors 
                        to generate fair odds for horse races.
                    </Card.Text>
                    <Button href = {'/horses'} className = {HomeStyle.button}>Read more</Button>
                </Card.Body>
                </Card>
                
                <Card className = {HomeStyle.card}>
                <Card.Body className = {HomeStyle.cardBody}>
                <Card.Title className={HomeStyle.info}><b>American Football</b></Card.Title>
                    <Card.Text className = {HomeStyle.par}>
                        Using bayesian statistics I built a model to predict outcomes of NFL games that I use to build 
                        DFS lineups and find +EV wagers.
                    </Card.Text>
                    <Button href = {'blog/#NFL'} className = {HomeStyle.button}>Read more</Button>
                </Card.Body>
                </Card>

                <Card className = {HomeStyle.card}>
                <Card.Body className = {HomeStyle.cardBody}>
                <Card.Title className={HomeStyle.info}><b>Baseball</b></Card.Title>
                    <Card.Text className = {HomeStyle.par}>
                        Using bayesian statistics I built a model to predict the outcomes of baseball games 
                        primarily using the MLB stats API and associated data.
                    </Card.Text>
                    <Button href = {'mlb'} className = {HomeStyle.button}>Read more</Button>
                </Card.Body>
                </Card>

                <Card className = {HomeStyle.card}>
                <Card.Body className = {HomeStyle.cardBody}>
                <Card.Title className={HomeStyle.info}><b>College Basketball</b></Card.Title>
                    <Card.Text className = {HomeStyle.par}>
                        The NCAA Men's Basketball tournament is fun.  I've found that it's more fun with analytics.  Here I've applied some machine learning for March Madness.
                    </Card.Text>
                    <Button href = {'/ncaab'} className = {HomeStyle.button}>Read more</Button>
                </Card.Body>
                </Card>

                <Card className = {HomeStyle.card}>
                <Card.Body className = {HomeStyle.cardBody}>
                    <Card.Title className={HomeStyle.info}><b>Pro Basketball</b></Card.Title>
                    <Card.Text className = {HomeStyle.par}>
                        Basketball used to be one of my favorite sports. I watch only occasionally now.  
                        I enjoy it even more when my Bayesian model is predicting game outcomes.
                    </Card.Text>
                    <Button href = {'/nba'} className = {HomeStyle.button}>Read more</Button>
                </Card.Body>
                </Card>

                <Card className = {HomeStyle.card}>
                <Card.Body className = {HomeStyle.cardBody}>
                <Card.Title className={HomeStyle.info}><b>Soccer</b></Card.Title>
                    <Card.Text className = {HomeStyle.par}>
                        Using bayesian statistics (same as the NFL model) I built a model to predict the outcomes of football games. 
                        I use to find positive expected value wagers.
                    </Card.Text>
                    <Button href = {'blog/#Soccer'} className = {HomeStyle.button}>Read more</Button>
                </Card.Body>
                </Card>
            </CardDeck>

        </Layout>
    )
}

export default IndexPage