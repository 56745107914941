import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql,Link } from "gatsby"
import styled from "styled-components"


const LogoWrap = styled.div`
  margin-left: 33%;
  margin-top: 5vh;
  flex: 0 1 36px;
  width: 33%;
  text-align: center;


  @media (max-width: 768px) and (orientation: landscape) {
    flex: 0 1 10px;
  }
`

const Biopic = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: {eq: "images/home.png"}) {
        childImageSharp {
          fluid(maxWidth: 10000, pngQuality: 1) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <LogoWrap>
      <Img fluid={data.file.childImageSharp.fluid} alt="logo" />
    </LogoWrap>
  )
}

export default Biopic